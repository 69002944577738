import React, { useState } from 'react'
import { Row, Col, Container, FancyShow } from '@kogk/common'
import cn from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faPlusCircle,
  faMinusCircle,
  faChevronDown
} from '@fortawesome/pro-light-svg-icons'
import styles from './QandA.module.scss'

export default ({ faqs = {}, heading }) => {
  const [openQuestions, setOpenQuestions] = useState([])
  const [openCategories, setOpenCategories] = useState([])

  const handleCategoryClick = item => {
    if (openCategories.includes(item)) {
      setOpenCategories(openCategories.filter(category => category !== item))
      // Also close all questions under the closing category
      setOpenQuestions(
        openQuestions.filter(
          question => !faqs[item].map(q => q.question.text).includes(question)
        )
      )
    } else {
      setOpenCategories([...openCategories, item])
    }
  }

  const handleQuestionClick = item => {
    if (openQuestions.includes(item)) {
      setOpenQuestions(openQuestions.filter(question => question !== item))
    } else {
      setOpenQuestions([...openQuestions, item])
    }
  }

  return (
    <Container className='pb-5' id="q-and-a">
      <Row>
        <Col className='col-lg-8 offset-lg-2'>
          {heading && (
            <FancyShow>
              <h1 className='hdln--2 pb-1 mb-3'>{heading}</h1>
            </FancyShow>
          )}
          <div>
            {Object.keys(faqs).map((category, key) => (
              <div key={key}>
                <div
                  className={styles.accordionCategoryItem}
                  role='button'
                  onClick={() => handleCategoryClick(category)}
                >
                  <div
                    className={cn(
                      'd-flex justify-content-between align-items-center',
                      {
                        [styles.accordionCategoryOpen]: openCategories.includes(
                          category
                        )
                      }
                    )}
                  >
                    <h2>{category}</h2>

                    <FontAwesomeIcon
                      icon={faChevronDown}
                      className={cn(styles.accordionCategoryArrow, {
                        [styles.accordionCategoryOpenArrow]: openCategories.includes(
                          category
                        )
                      })}
                    />
                  </div>
                </div>
                <div
                  className={cn(
                    'mb-3',
                    styles.accordion,
                    {
                      [styles.accordionOpen]: openCategories.includes(category)
                    }
                  )}
                >
                  {faqs[category] &&
                    faqs[category].map((item, i) => {
                      const icon = openQuestions.includes(item.question.text)
                        ? faMinusCircle
                        : faPlusCircle

                      return (
                        <div key={i}>
                          <div
                            className={styles.accordionItem}
                          >
                            <button
                              onClick={() =>
                                handleQuestionClick(item.question.text)
                              }
                              className={cn(
                                styles.accordionHeader,
                                'button-no-style white'
                              )}
                            >
                              <FontAwesomeIcon icon={icon} />
                              <div
                                className={cn('m-0', styles.question)}
                                dangerouslySetInnerHTML={{
                                  __html: item.question.html
                                }}
                              />
                            </button>
                            <div
                              className={cn(
                                styles.answer,
                                {
                                  [styles.open]: openQuestions.includes(
                                    item.question.text
                                  )
                                },
                                'parag-small'
                              )}
                              dangerouslySetInnerHTML={{
                                __html: item.answer
                              }}
                            />
                          </div>
                        </div>
                      )
                    })}
                </div>
              </div>
            ))}
          </div>
        </Col>
      </Row>
    </Container>
  )
}
